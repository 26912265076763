<template>
  <div>
    <van-notice-bar left-icon="volume-o" color="#1989fa" background="#ecf9ff" mode="closeable">每天早上八点至晚上十点开放校区实况。</van-notice-bar>
    <div class="container">
      <van-list v-model="listLoading" class="list" :finished="listFinished" :finished-text="listFinishedText" @load="onListLoad">
        <div v-for="item in list" :key="item.monitor_id" class="list-item" @click="play(item)">
          <div class="list-item-inner">
            <div :id="`dplayer_${item.monitor_id}`"></div>
            <div class="list-item-title"></div>
            <van-cell :title="`${item.studio_name} - ${ item.room_name }`" />
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>

import PageMixin from '@/mixins/page'
import Hls from 'hls.js'
import DPlayer from 'dplayer';
import { NoticeBar} from 'vant'

export default {
  name: 'Monitor',
  mixins: [PageMixin],
  data() {
    return {
      list: [],
      dp: [],
      playerOtions: [],
      listLoading: false,
      listFinished: false,
      listFinishedText: '没有更多了',
      pageSize: 10,
      pageCurrent: 1
    }
  },
  components: {
      [NoticeBar.name]:NoticeBar,
  },
  created() {

  },
  beforeDestroy() {
    this.dp.forEach(dpayer=>{
      if(dpayer) {
        dpayer.destroy()
      }
    })
  },
  methods: {
    play(item){
      console.log(item);
    },
    onListLoad() {
      this.onPage()
    },
    onPage() {
      this.$api.campus_monitor({ 'per-page': this.pageSize, page: this.pageCurrent }).then(res => {
        console.log(res);
        this.listLoading = false
        this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
        this.$nextTick(() => {
          this.list.map(item=>{
              let option = {
                container: document.getElementById('dplayer_'+item.monitor_id),
                mutex:false,
                autoplay:false,
                preload:'none',
                volume:0,
                screenshot: true,
                live: true,
                video: {
                    url: item.monitor_play_url,
                    type: 'customHls',
                    customType: {
                        customHls: function (video, player) {
                            const hls = new Hls({
                              p2pConfig: {
                                live: true, // 如果是直播设为true
                              },
                            });
                            hls.loadSource(video.src);
                            hls.attachMedia(video);
                            player.events.on('destroy', () => {
                                hls.stopLoad();
                                hls.destroy();
                            });
                        },
                    },
                  }
              }
              this.dp.push(new DPlayer(option))
          })
        })

        this.listFinished = this.list.length >= res._meta.totalCount
        this.listFinishedText = this.list.length ? '没有更多了' : '暂无相关数据~'
        this.pageCurrent += 1
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .container{
    padding: 10px 0px;
  }
  .list{

  }
  .list-item{
    margin-bottom: 20px;
    &-time{
      text-align: center;
      font-size: 10px;
      color: #999;
      margin-bottom: 6px;
    }
    &-inner{
      padding: 0px 0px;
      background: #fff;
      border-radius: 8px;
    }
    &-title{
      font-size: 16px;
      color: #333;
      margin-bottom: 6px;
      display: flex;
      align-items: flex-start;
    }
    &-info{
      font-size: 14px;
      color: #666;
    }
    &-dot{
      display: inline-block;
      width: 8px;
      height: 8px;
      background-color: #ee0a24;
      border-radius: 50%;
      margin-left: 5px;
    }
  }
</style>

